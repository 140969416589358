import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import emision1 from "../../../static/img/emision-deudas/emision1.jpg"
import emision2 from "../../../static/img/emision-deudas/emision2.jpg"
import emision3 from "../../../static/img/emision-deudas/emision3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Debt Issuance Program"/>
            <Banner title="Debt Issuance Program"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Client’s profile</h3>

                                <p>A Mexican telecommunications company has constantly
                                    expanded its
                                    infrastructure through the signing of revolving and syndicated loans. However, the
                                    company wants to
                                    perform a corporate refinancing to extend loan terms without implying a constant
                                    renegotiation and
                                    reduce interest payments. For this reason, the company plans to take advantage of
                                    the
                                    low interest rate
                                    environment in the international markets; therefore, it decided to issue a
                                    Eurodollar
                                    bond.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={emision1} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={emision2} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandate</h3>

                                <p>Through an exhaustive due diligence, the Company
                                    decided to
                                    contract IRStrat’s Debt Issuance solution, with the mandate to ease the Eurobond
                                    issuance, shorten the
                                    execution time and minimize the cost of the instrument.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solution</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row mt-3 step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Strategy
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat performs a preliminary market
                                            study and assesses
                                            the client's issuance scope in the international debt markets, to elaborate
                                            a detailed action plan
                                            for achieving an optimal placement. Likewise, IRStrat prepares a work agenda
                                            detailing the precise
                                            steps to complete the filing of the Eurobond prospectus and determine its
                                            indenture.</p>
                                            <p>Our team, working with the senior management, begins to develop the
                                                company’s investment
                                                thesis, history and competitive advantage, as well as the communication
                                                style (qualitative and
                                                quantitative) and an optimal communication strategy.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Tactic
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>IRStrat implements a thorough training of our
                                            client's executive team
                                            in roadshow presentations, face-to-face meetings and specialized forums. The
                                            Prospectus and
                                            investor presentations are insightfully prepared, and a clear and impactful
                                            fact sheet containing
                                            the investment thesis and positive aspects of the instruments is
                                            developed.</p>
                                            <p>IRStrat carries out the targeting and beauty contest of the underwriter
                                                syndicate, the
                                                targeting of institutional investors, the national and international
                                                roadshow, and elaborates a
                                                proxy of the closed book. Likewise, an intensive lobbying is carried out
                                                with investment and
                                                pension funds, as well as presentations with family offices, endowments,
                                                credit rating agencies,
                                                specialized media and blue-chip mutual funds.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Results
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Our client successfully carried out its 300
                                            million-dollar Eurobond
                                            placement with a 7-year term and a cost of debt 250 basis points lower than
                                            its previous debt
                                            structure of short-term revolving credit lines; producing substantial
                                            savings to its treasury. The
                                            closed book was composed of long-term investors, mostly mutual funds and
                                            pension funds, with a
                                            minimum participation of hedge funds and other alpha generators.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={emision3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
